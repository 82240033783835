import React, { useEffect, useState } from 'react';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem } from 'reactstrap';
import { Link } from 'react-scroll';
import Logo from '../images/logo.png';

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const HandleScroll = () => {
    if (window.scrollY >= 60) {
      document.getElementById('Navbar')?.classList.add('darkHeader');
    } else document.getElementById('Navbar')?.classList.remove('darkHeader');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      document.querySelector('.loader-wrapper').style = 'display: none';
    }, 2000);
    window.addEventListener('scroll', HandleScroll);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <Navbar id="Navbar" className="navbar navbar-expand-lg navbar-light theme-nav fixed-top">
      <div id="navbar-main" className="container">
        <NavbarBrand className="navbar-brand" href="">
          <img src={Logo} alt="logo" />
        </NavbarBrand>
        <NavbarToggler className="navbar-toggler" onClick={toggle} />
        <Collapse id="navbarSupportedContent" className="default-nav" isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <Link
                className="nav-link"
                activeClass="active"
                to="about"
                spy
                smooth
                offset={-70}
                duration={500}
                onClick={toggle}
              >
                O publikacji
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="nav-link"
                activeClass="active"
                to="feature"
                spy
                smooth
                offset={-70}
                duration={500}
                onClick={toggle}
              >
                Co w niej
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="nav-link"
                activeClass="active"
                to="screenshot"
                spy
                smooth
                offset={-70}
                duration={500}
                onClick={toggle}
              >
                Prezentacja
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="nav-link"
                activeClass="active"
                to="testimonial"
                spy
                smooth
                offset={-70}
                duration={500}
                onClick={toggle}
              >
                Opinie
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="nav-link"
                activeClass="active"
                to="contact"
                spy
                smooth
                offset={-70}
                duration={500}
                onClick={toggle}
              >
                Zamów
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="nav-link"
                activeClass="active"
                to="footer"
                spy
                smooth
                offset={-70}
                duration={500}
                onClick={toggle}
              >
                Kontakt
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  );
};

export default Menu;
